import { gql } from '@apollo/client';

export const GET_PROFILE_INFO = gql`
query
    {
        user
        {
            dashboard
            {
                me
                {
                    id
                    firstName
                    lastName
                    description
                    email
                    photoUrl
                    gender
                    phone
                    notificationTypes
                    birthDate
                    preferredLanguages
                    selectedLanguage
                    address
                    {
                        country
                        city
                        address
                        zipcode
                    }
                    professionalNumber
                    specialities
                    subOrganizations
                    {
                        id
                        name
                    }
                    careTeams {
                        id
                        name
                    }
                    roleIds
                    roles
                    newRoles
                    {
                        id
                        name
                        type
                        permissions
                        {
                            id,
                            name,
                            permissions
                            {
                                id,
                                name,
                                permissions
                                {
                                    c,
                                    r,
                                    u,
                                    d
                                }
                            }
                        }
                    }
                    permissions
                    {
                        id,
                        name,
                        permissions
                        {
                            c,
                            r,
                            u,
                            d
                        }
                    }
                    sendbirdId
                    workingHours{
                        dayOfWeek,
                        morningOpeningTime,
                        morningClosingTime,
                        afternoonOpeningTime,
                        afternoonClosingTime
                    }
                    appointmentDuration
                }
            }
        }
    }
`;

