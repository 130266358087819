import { createSlice } from '@reduxjs/toolkit';
import { useHistory } from 'react-router-dom';
import { persistReducer } from 'redux-persist';
import localStorage from 'redux-persist/lib/storage';

export const getModuleState = (state) => state.auth;

export const selectors = {
  isAuthenticated(state) {
    const { isAuthenticated } = getModuleState(state);
    return isAuthenticated;
  },
  getAccessToken(state) {
    const { accessToken } = getModuleState(state);
    return accessToken;
  },
  getAuthMethods(state) {
    const { authMethods } = getModuleState(state);
    return authMethods;
  },
  getUserProfile(state) {
    const { userProfile } = getModuleState(state);
    return userProfile;
  },
  getUserPoolIds(state) {
    const { userPoolId, clientId } = getModuleState(state);
    return { userPoolId, clientId };
  },
  getWorkspace(state) {
    const { workspace } = getModuleState(state);
    return workspace;
  },
  getWorkspaceType(state) {
    const { workspaceType } = getModuleState(state);
    return workspaceType;
  },
  getSendbirdData(state) {
    const { sendbirdData } = getModuleState(state);
    return sendbirdData;
  },
  getLogo(state) {
    const { whiteLabelLoginLogoUrl, whiteLabelLogoUrl } = getModuleState(state);
    return { whiteLabelLoginLogoUrl, whiteLabelLogoUrl };
  },
  getIsDemo(state) {
    const { isDemo } = getModuleState(state);
    return isDemo;
  },
  getWorkspaceMfaMode(state) {
    const { mfaMode } = getModuleState(state);
    return mfaMode;
  },
};

const slice = createSlice({
  name: 'auth',
  initialState: {
    authMethods: [],
    isAuthenticated: false,
    userProfile: null,
    workspace: null,
    workspaceType: null,
    userPoolId: null,
    clientId: null,
    session: null,
    username: null,
    sendbirdData: {},
    sendbirdSessionTokens: null,
    whiteLabelLogoUrl: null,
    whiteLabelLoginLogoUrl: null,
    isDemo: false,
    mfaMode: null,
  },
  reducers: {
    refreshSession(state, { payload }) {
      state.session = payload;
    },
    login(state, { payload }) {
      const { username, session, sendbirdSessionTokens } = payload;

      state.isAuthenticated = true;
      state.session = session;
      state.username = username;
      state.sendbirdSessionTokens = sendbirdSessionTokens;
    },
    logout(state) {
      state.isAuthenticated = false;
      state.session = null;
      state.userProfile = {};
      state.username = null;
      state.sendbirdSessionTokens = null;
      window.location.reload();
      // just to generate action creator, see rootReducer - it resets the whole state
    },
    setWorkspace(state, { payload }) {
      const {
        workspace,
        userPoolId,
        clientId,
        workspaceType,
        authMethods,
        isDemo,
        mfaMode,
      } = payload;

      state.workspace = workspace;
      state.userPoolId = userPoolId;
      state.clientId = clientId;
      state.workspaceType = workspaceType;
      state.authMethods = authMethods;
      state.isDemo = isDemo;
      state.mfaMode = mfaMode;
    },
    setSendbirdData(state, { payload }) {
      state.sendbirdData = payload;
    },
    setLogos(state, { payload }) {
      const { whiteLabelLoginLogoUrl, whiteLabelLogoUrl } = payload;
      state.whiteLabelLoginLogoUrl = whiteLabelLoginLogoUrl;
      state.whiteLabelLogoUrl = whiteLabelLogoUrl;
    },
  },
});

export const actions = slice.actions;
export const reducer = persistReducer(
  {
    key: 'auth',
    storage: localStorage,
  },
  slice.reducer
);
